import Link from 'next/link'
import classnames from 'classnames'
import { Container } from '@components/Container/Container'
import styles from '@styles/custom404.module.scss'

const Custom404 = () => {
  return (
    <main className={styles.custom404}>
      <Container additionalStyles={styles.wrapper}>
        <img alt="404" src="/lamp.png" />
        <div className={styles.content}>
          <h1>404</h1>
          <h2>Looks you are lost!</h2>
          <p>The page you are looking for does not exists</p>
          <Link legacyBehavior href="/">
            <a className={classnames('linkAsBtn', 'button')} href="/">
              GO TO THE HOMEPAGE
            </a>
          </Link>
        </div>
      </Container>
    </main>
  )
}

export default Custom404
